import React from 'react';
import styled from 'styled-components';
import Cards from './_data';
const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.16rem 0 0;
  background: #f5f5f5;
  > h2 {
    color: #636363;
    font-size: 0.32rem;
    position: relative;
    &:after {
      position: absolute;
      content: '';
      width: 0.58rem;
      height: 0.08rem;
      background: #ff7c34;
      left: 50%;
      transform: translateX(-50%);
      bottom: -0.4rem;
    }
  }

  .cards {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: center;
    padding: 0.4rem 0 0;
    .card {
      display: flex;
      align-items: center;
      padding: 0.51rem 0.64rem;
      width: 100%;
      &:not(:last-child) {
        border-bottom: 1px solid #e6e6e6;
      }
      .icon {
        width: 1rem;
        margin-right: 0.43rem;
      }
      .content {
        > h3 {
          font-size: 0.26rem;
          color: #2c2c2c;
          font-weight: bold;
          margin-bottom: 0.22rem;
        }
        > h4 {
          font-size: 0.22rem;
          color: #2c2c2c;
          font-weight: bold;
          margin-bottom: 0.27rem;
        }
        > ul {
          li {
            color: #787878;
            font-size: 0.18rem;
            line-height: 2;

            &:before {
              content: '★';
              display: inline-block;
              font-size: 0.18rem;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
  .btn {
    background: rgb(255, 124, 52);
    box-shadow: 0px 6px 10px 0px rgba(172, 61, 0, 0.34);
    border-radius: 27px;
    padding: 1rem 2rem;
    color: #fff;
  }
`;
export default function Banner() {
  return (
    <Wrapper>
      <h2>为什么选择壹点壹滴幼教云？</h2>
      <div className="cards">
        {Cards.map((c, idx) => {
          const { title, subTitle, list, icon } = c;
          return (
            <div key={title} className={`card ${idx === 2 ? 'noRightM' : ''}`}>
              <img src={icon} alt="产品图标" className="icon" />
              <div className="content">
                <h3>{title}</h3>
                <h4>{subTitle}</h4>
                <ul>
                  {list.map((l) => {
                    return <li key={l}>{l}</li>;
                  })}
                </ul>
              </div>
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
}
