import IconDaimond from 'imgs/1d1d/pc/icon.daimond.png';
import IconProfile from 'imgs/1d1d/pc/icon.profile.png';
import IconGroup from 'imgs/1d1d/pc/icon.group.png';
import IconCao from 'imgs/1d1d/m/caozuo.png';
import IconGong from 'imgs/1d1d/m/gongneng.png';
import IconMo from 'imgs/1d1d/m/moshi.png';
import IconPin from 'imgs/1d1d/m/pinpai.png';
import IconPro from 'imgs/1d1d/m/professional.png';
import IconYuan from 'imgs/1d1d/m/ziyuan.png';
const Cards = [
  {
    title: '高效招生',
    subTitle: '打造智能招生平台，轻松实现高效招生',
    list: [
      '招生方案：可编辑的活动模板+高转化率的创意方案，驱动家长圈的快速裂变',
      '招生海报：每日提供裂变采单海报，自动筛选精准客户',
      '招生微课：提供新颖多样、效果显著的招生活动视频指导'
    ],
    icon: IconDaimond
  },
  {
    title: '专业师训',
    subTitle: '搭建幼师专业化培训平台，增进教师的岗位胜任力',
    list: [
      '幼师学院：幼儿园一线教师的岗前自动化培训系统',
      '带班助手：帮助老师轻松找到带班所需资源',
      '专家引领：幼教专家在线指导，实现教师的快速成长'
    ],
    icon: IconProfile
  },
  {
    title: '园所管理',
    subTitle: '打造园所数字化管理系统，让管理更轻松更高效',
    list: [
      '园长商学院：幼教大咖亲力打造，助力园长成为全能型的管理者',
      '家园沟通：帮助园所打破家园沟通黑匣子，让家长真正成为幼儿教育的同盟军',
      '彩虹管控点：倾力打造10+4X园所安全彩虹管控点，为幼儿园守住安全底线'
    ],
    icon: IconGroup
  }
];
const Enables = [
  {
    title: '专属品牌',
    desc: '幼儿园享有公众号所有权，所有内容以幼儿园名义发布和分享，锻造幼儿园专属品牌。',
    img: IconPin
  },
  {
    title: '创新模式',
    desc: '线上线下相融合，打造“幼教+互联网”崭新模式。',
    img: IconMo
  },
  {
    title: '操作便捷',
    desc: '公众号操作简便，家长、教师轻松掌握，拿来即用。',
    img: IconCao
  },
  {
    title: '功能全面',
    desc: '涵盖幼儿园各方面的工作业务，让管理更高效。',
    img: IconGong
  },
  {
    title: '优质资源',
    desc: '跨界整合业内优质的资源，全方面赋能幼儿园。',
    img: IconYuan
  },
  {
    title: '甄选专家',
    desc: '甄选幼教行业专家团队支招解惑，助力幼儿园专业成长。',
    img: IconPro
  }
];
export { Enables };
export default Cards;
