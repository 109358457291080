import React from 'react';
import styled from 'styled-components';
import { Enables } from './_data';
const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.75rem 0 1.02rem 0;
  background: #fff;
  > h2 {
    color: #636363;
    font-size: 0.32rem;
    position: relative;
    width: 5.1rem;
    text-align: center;
    &:after {
      position: absolute;
      content: '';
      width: 0.58rem;
      height: 0.08rem;
      background: #ff7c34;
      left: 50%;
      transform: translateX(-50%);
      bottom: -0.82rem;
    }
  }
  > h3 {
    color: #787878;
    font-size: 0.22rem;
    margin-top: 0.22rem;
  }
  .enables {
    padding: 0.62rem 0.28rem 0;

    .list {
      .enable {
        display: flex;
        align-items: center;
        height: 1.68rem;
        background-image: linear-gradient(#ffffff, #ffffff), linear-gradient(#f5f5f5, #f5f5f5);
        background-blend-mode: normal, normal;
        box-shadow: 0px 0px 0.142rem 0.8px rgba(96, 96, 96, 0.2);
        border-radius: 0.1rem;
        padding: 0 0.34rem;
        &:not(:last-child) {
          margin-bottom: 0.2rem;
        }
        img {
          width: 1.19rem;
          height: 1.19rem;
          margin-right: 0.3rem;
        }
        h4 {
          color: #2c2c2c;
          font-size: 0.26rem;
          position: relative;
        }
        p {
          margin-top: 0.22rem;
          color: #787878;
          font-size: 0.22rem;
        }
        .content {
          width: 4.84rem;
        }
      }
    }
  }
`;
export default function Banner() {
  return (
    <Wrapper>
      <h2>壹点壹滴用专业内容和互联网技术为幼儿园赋能</h2>
      <h3>全国已有20000+幼儿园选择使用新幼教云平台</h3>
      <div className="enables">
        <div className="list">
          {Enables.map((able) => {
            const { title, desc, img } = able;
            return (
              <div key={title} className={`enable`}>
                <img src={img} alt={title} />
                <div className="content">
                  <h4>{title}</h4>
                  <p>{desc}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Wrapper>
  );
}
